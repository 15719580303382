(function ($) {
  $(function () {
    //Home activities
    $(".related-posts").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      nextArrow: ".carousel-next",
      prevArrow: ".carousel-prev",
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            arrows: false,
            dots: true,
            centerMode: true,
            centerPadding: "40px",
          },
        },
      ],
    });

    //Home terrains de jeu
    $(".terrains-slide").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
    });

    //Logos partenaires home
    $(".autoslide").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 2000,
      nextArrow:
        "<img class='next-btn' src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEzLjE3MTQgMTIuMDAwN0w4LjIyMTY4IDcuMDUwOTNMOS42MzU4OSA1LjYzNjcyTDE1Ljk5OTkgMTIuMDAwN0w5LjYzNTg5IDE4LjM2NDZMOC4yMjE2OCAxNi45NTA0TDEzLjE3MTQgMTIuMDAwN1oiPjwvcGF0aD48L3N2Zz4='>",
      prevArrow:
        "<img class='prev-btn' src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwLjgyODQgMTIuMDAwN0wxNS43NzgyIDE2Ljk1MDRMMTQuMzY0IDE4LjM2NDZMOCAxMi4wMDA3TDE0LjM2NCA1LjYzNjcyTDE1Ljc3ODIgNy4wNTA5M0wxMC44Mjg0IDEyLjAwMDdaIj48L3BhdGg+PC9zdmc+'>",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            arrows: false,
            dots: false,
            centerMode: false,
            centerPadding: "45px",
          },
        },
      ],
    });
  });

  //single activites image slider
  $(".terrain-post-slider").each(function () {
    $(this).slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      fade: false,
      dots: false,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      nextArrow:
        "<img class='next-btn' src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEzLjE3MTQgMTIuMDAwN0w4LjIyMTY4IDcuMDUwOTNMOS42MzU4OSA1LjYzNjcyTDE1Ljk5OTkgMTIuMDAwN0w5LjYzNTg5IDE4LjM2NDZMOC4yMjE2OCAxNi45NTA0TDEzLjE3MTQgMTIuMDAwN1oiPjwvcGF0aD48L3N2Zz4='>",
      prevArrow:
        "<img class='prev-btn' src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTEwLjgyODQgMTIuMDAwN0wxNS43NzgyIDE2Ljk1MDRMMTQuMzY0IDE4LjM2NDZMOCAxMi4wMDA3TDE0LjM2NCA1LjYzNjcyTDE1Ljc3ODIgNy4wNTA5M0wxMC44Mjg0IDEyLjAwMDdaIj48L3BhdGg+PC9zdmc+'>",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: true,
            centerMode: true,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            arrows: false,
            dots: true,
            centerMode: true,
            centerPadding: "45px",
          },
        },
      ],
    });
  });
})(jQuery);
console.log("slick init");
